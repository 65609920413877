import React from "react";
import { useDispatch } from "react-redux";
import { PAGES } from "../../constants/options";
import { reqSetIsShowGuidePopup, reqSetIsShowIntroFutureBtn, reqSetPage } from "../../reduxs/home/action";
import './mobile.scss';

const GuidePopupMobile = () => {
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(reqSetIsShowGuidePopup(false))
    dispatch(reqSetPage(PAGES.INTERACTIVE_TOUR));
    dispatch(reqSetIsShowIntroFutureBtn(true));
  }

  return (
    <div className="wrap-guide-popup-mobile">
      <div className="wrap-popup-content">
        <div className="popup-body m-0">
          <div>
            <div className="wrap-intro-action">
              <div className="h-100 d-flex flex-column justify-content-around">
              <div className="item"><img className="img-fluid" src="./icons/intro_334.svg" alt="" /></div>
                <div className="item"><img className="img-fluid" src="./icons/intro_340.svg" alt="" /></div>
                <div className="item"><img className="img-fluid" src="./icons/intro_339.svg" alt="" /></div>
              </div>
            </div>
            <div className="wrap-intro-description">
              <div className="intro-description">
                <h4 className="title">DISCOVERING EYNESBURY</h4>
                <div className="description">Lead your own interactive tour of the Eynesbury township through our customised 3D map, animation, videos and imagery.</div>
              </div>
              <div onClick={handleClosePopup} className="btn-explore"><img className="img-fluid cursor-pointer" src="./icons/interact.svg" alt="" /></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default GuidePopupMobile;
