import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqSetActiveLocation } from "../../reduxs/category/action";
import { reqSetExploreModal } from "../../reduxs/explore-modal/action";
import { reqSetActiveCategory, reqSetIsCollapsedCategoryDetail, reqSetIsShowCategoryDetail, reqSetIsShowSidebar } from "../../reduxs/home/action";
import { CSSTransition } from 'react-transition-group';
import './category-detail.scss';
import { useRef } from "react";
import { PAGES } from "../../constants/options";
import { reqSetActiveType } from "../../reduxs/locations/action";

const CategoryDetail = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.home.activeCategory);
  const isCollapsedCategoryDetail = useSelector((state) => state.home.isCollapsedCategoryDetail);
  const page = useSelector((state) => state.home.page);
  const isShowCategoryDetail = useSelector((state) => state.home.isShowCategoryDetail);
  const nodeRef = useRef(null);

  const onBackToCategoryList = () => {
    dispatch(reqSetActiveCategory(undefined));
    dispatch(reqSetIsShowCategoryDetail(false));
    dispatch(reqSetIsShowSidebar(true));
    dispatch(reqSetActiveType(''));
  }

  const handleSelectLocation = (item) => {
    dispatch(reqSetActiveLocation(item.id));
    dispatch(reqSetExploreModal(item.link));
  }

  const onClose = () => {
    dispatch(reqSetIsShowCategoryDetail(false));
  }

  const onCollapsed = () => {
    dispatch(reqSetIsCollapsedCategoryDetail(true));
  }

  const onExpand = () => {
    dispatch(reqSetIsCollapsedCategoryDetail(false));
  }

  const renderListLocations = () => {
    return (activeCategory?.locations || []).map(item => {

      return (
        <div className="category" key={item.id} onClick={() => handleSelectLocation(item)}>
          <span>{item.index} {item.name}</span>
          <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.19995 11.2L5.19995 7.20001L1.19995 3.20001L1.99995 1.60001L7.59995 7.20001L1.99995 12.8L1.19995 11.2Z" fill="#B8B5B8" stroke="#B8B5B8" />
          </svg>
        </div>
      )
    });
  }

  const getClassName = () => {
    return (page == PAGES.INTERACTIVE_TOUR) && isShowCategoryDetail ? '' : 'invisible'
  }

  const CollapseButton = () => {
    if (isCollapsedCategoryDetail) {
      return (
        <button onClick={onExpand}>
          <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13.5L6 8.5L11 13.5M1 6.5L6 1.5L11 6.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      )
    }

    return (
      <button onClick={onCollapsed}>
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 0.999999L6 6L11 1M1 8L6 13L11 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
    )
  }

  return (
    <div className={getClassName()}>
      <CSSTransition in={isCollapsedCategoryDetail} timeout={500} classNames="fade-up-apart">
        <div ref={nodeRef} className="wrap-category-detail-mobile">
          <div className="tray" onClick={onClose} />
          <div className="content">
            <div className="body">
              <div className="header">
                <button onClick={onBackToCategoryList}>
                  <svg className="back-btn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <span>{activeCategory?.name}</span>
                <CollapseButton/>
                {/* <button onClick={onClose}>
                  <svg className="close-btn" viewBox="0 0 24 24" role="img" tabIndex="-1" xmlns="http://www.w3.org/2000/svg"><path d="M20 20L4 4m16 0L4 20" /></svg>
                </button> */}
              </div>
              <div className="wrap-list-categories">
                {renderListLocations()}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default CategoryDetail;
