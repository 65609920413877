import React, { useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelector } from "react-redux";
import Loading from '../../components/loading/mobile';
import { PAGES, LAYERS } from "../../constants/options";
import BottomNavigation from "../../components/bottom-navigation/mobile";
import { threePosition } from '../../helper/threeHeper';
import './mobile.scss';
import TopNav from "../../components/top-nav/mobile";
import { useDispatch } from "react-redux";
import { reqSetIsShowFuture, reqSetIsShowGuidePopup, reqSetPage, reqSetIsShowUnSupportedWebGLPopup} from "../../reduxs/home/action";
import GuidePopup from "../../components/guide-popup/mobile";
import HomeGallery from "../../components/home-gallery/mobile";
import LegendSidebar from '../../components/legend-sidebar/mobile';
import SidebarCollapsed from "../../components/legend-sidebar/SidebarCollapsed";
import CategoryDetail from "../../components/legend-sidebar/CategoryDetail";
import LocationDetail from "../../components/legend-sidebar/LocationDetail";
import VirtualGallery from "../../components/virtual-gallery/mobile";
import VirtualTour from "../../components/virtual-gallery/VirtualTour";
import VideoIntro from "../../components/video-intro/mobile";
import IntroFutureBtn from "../../components/guide-popup/intro-future-btn";
import UnsupportedWebGLPopup from '../../components/unsupported-webgl-popup';

const Mobile = (props) => {
  const { setIsIntroduction, controls, refScene } = props;

  const dispatch = useDispatch();

  const page = useSelector((state) => state.home.page);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const isShowFuture = useSelector((state) => state.home.isShowFuture);
  const isShowGuidePopup = useSelector((state) => state.home.isShowGuidePopup);
  const transportOptionDistricts = useSelector((state) => state.home.transportOptionDistricts);
  const isShowSidebar = useSelector((state) => state.home.isShowSidebar);
  const activeLocation = useSelector((state) => state.category.activeLocation);
  const isShowVirtualTour = useSelector((state) => state.home.isShowVirtualTour);
  const isShowIntroFutureBtn = useSelector((state) => state.home.isShowIntroFutureBtn);
  const isShowUnSupportedWebGLPopup = useSelector((state) => state.home.isShowUnSupportedWebGLPopup);
  const isWebGLSupported = useSelector((state) => state.home.isWebGLSupported);



  useEffect(() => {
    if (page == PAGES.LANDING_PAGE) {
      setTimeout(() => {
        if (!isWebGLSupported) {
          return;
        }
        
        dispatch(reqSetIsShowGuidePopup(true));
      }, 1800)
    }
  }, [page]);

  const handleClickPresent = () => {
    controls.current.hideLayer(LAYERS.FUTURE);
    controls.current.showAndEnableLayer(LAYERS.EXISTING);
    dispatch(reqSetIsShowFuture(false));
  };

  const handleClickFuture = () => {
    controls.current.hideLayer(LAYERS.EXISTING);
    controls.current.showAndEnableLayer(LAYERS.FUTURE);
    dispatch(reqSetIsShowFuture(true));
  };

  const handleClickGallery = () => {
    resetState();
    dispatch(reqSetPage(PAGES.GALLERY_PAGE));
  };

  const handleClickVirtualGallery = () => {
    resetState();
    dispatch(reqSetPage(PAGES.VIRTUAL_GALLERY));
  };

  const handleClickGuidedTour = () => {
    resetState();
    dispatch(reqSetPage(PAGES.GUIDED_TOUR_PAGE));
  };

  const resetState = () => {
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
  };

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(setting => setting.name == page);
    if (settings.length == 0) {
      return <div />;
    }
    if (controls.current == null || controls.current.currentPage == page) {
      return <div />;
    }
    controls.current.currentPage = page;
    const setting = settings[0];


    if (setting.camera && setting.camera_look_at_position) {
      const camPosition = threePosition(setting.camera);
      const camLookAtPosition = threePosition(setting.camera_look_at_position);
      controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, setting.duration ?? 2, () => {
        controls.current.state = -1;
      });
    }

    if (Array.isArray(setting.show_layers) &&
      Array.isArray(setting.enable_layers)) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(LAYERS.NONE);

      for (let i = 0; i < setting.show_layers.length; i += 1) {
        controls.current.showLayer(setting.show_layers[i]);
      }
      for (let i = 0; i < setting.enable_layers.length; i += 1) {
        controls.current.enableLayer(setting.enable_layers[i]);
      }

      if (isShowFuture) {
        controls.current.showAndEnableLayer(LAYERS.FUTURE);
      } else {
        controls.current.showAndEnableLayer(LAYERS.EXISTING);
      }
    }
    return <div />
  });

  HandleSetActivePage.displayName = 'HandleSetActivePage';

  const handleClickInteractiveTour = () => {
    resetState();
    dispatch(reqSetPage(PAGES.INTERACTIVE_TOUR));
    if (!isWebGLSupported) {
      dispatch(reqSetIsShowUnSupportedWebGLPopup(true));
    }
  }

  const FutureBtn = () => {
    if (isShowFuture) {
      return (<div className="wrap-btn-future d-flex align-items-center position-absolute">
        <button onClick={handleClickPresent} className="d-flex justify-content-center align-items-center">
          <span className="me-2">Future: On</span>
          <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7 9.5L18.7005 11.5L16.7 9.5M18.9451 11C18.9814 10.6717 19 10.338 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C12.8273 19 15.35 17.6963 17 15.6573M10 5V10L13 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>);
    }

    return (
      <div className="wrap-btn-future d-flex align-items-center position-absolute">
        <button onClick={handleClickFuture} className="d-flex justify-content-center align-items-center future-off">
          <span className="me-2">Future: Off</span>
          <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7 9.5L18.7005 11.5L16.7 9.5M18.9451 11C18.9814 10.6717 19 10.338 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C12.8273 19 15.35 17.6963 17 15.6573M10 5V10L13 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <>
      <HandleSetActivePage pagesSettings={pagesSettings} page={page} />
      <TopNav />
      <SidebarCollapsed />
      <FutureBtn />
      <BottomNavigation
        handleClickGallery={handleClickGallery}
        handleClickInteractiveTour={handleClickInteractiveTour}
        handleClickVirtualGallery={handleClickVirtualGallery}
        handleClickGuidedTour={handleClickGuidedTour}
      />

      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
        />
      )}

{
        isShowIntroFutureBtn && (
          <TransitionGroup>
            <CSSTransition in={isShowIntroFutureBtn} timeout={200} classNames="fade-item" unmountOnExit>
              <IntroFutureBtn />
            </CSSTransition>
          </TransitionGroup>
        )
      }

      <TransitionGroup>
        {
          (page == PAGES.LANDING_PAGE && isShowGuidePopup) && (
            <CSSTransition
              timeout={50}
              classNames="fade-item"
              unmountOnExit
            >
              <GuidePopup />
            </CSSTransition>
          )
        }
      </TransitionGroup>

      <TransitionGroup>
        {
          (page == PAGES.GALLERY_PAGE) && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <HomeGallery />
            </CSSTransition>
          )
        }
      </TransitionGroup>

      <TransitionGroup>
        {
          (page == PAGES.VIRTUAL_GALLERY) && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <VirtualGallery />
            </CSSTransition>
          )
        }
      </TransitionGroup>

      <TransitionGroup>
        {

          (page == PAGES.INTERACTIVE_TOUR && isShowSidebar) && (
            <CSSTransition in={isShowSidebar} timeout={500} classNames="fade-up-big">
              <LegendSidebar />
            </CSSTransition>
          )
        }
      </TransitionGroup>

      {
        (
          <CategoryDetail />
        )
      }

      <TransitionGroup>
        {
          (page == PAGES.INTERACTIVE_TOUR && activeLocation) && (
            <CSSTransition timeout={500} classNames="fade-up-big">
              <LocationDetail />
            </CSSTransition>
          )
        }
      </TransitionGroup>


      <TransitionGroup>
        {

          (isShowVirtualTour) && (
            <CSSTransition timeout={200} classNames="fade-item">
              <VirtualTour />
            </CSSTransition>
          )
        }
      </TransitionGroup>


      <TransitionGroup>
        {
          ((page == PAGES.LANDING_PAGE || page == PAGES.INTERACTIVE_TOUR) && isShowUnSupportedWebGLPopup) && (
            <CSSTransition
            timeout={50}
            classNames="fade-item"
            unmountOnExit
          >
            <UnsupportedWebGLPopup />
          </CSSTransition>
          )
        }
      </TransitionGroup>

      <TransitionGroup>
        {
          page == PAGES.GUIDED_TOUR_PAGE && (
            <CSSTransition
              timeout={500}
              classNames="fade-item"
              unmountOnExit
            >
              <VideoIntro />
            </CSSTransition>
          )
        }
      </TransitionGroup>

    </>
  );
}

export default Mobile;
