import React from 'react';
import './index.scss';

import { useDispatch } from 'react-redux';
import { reqSetIsShowUnSupportedWebGLPopup } from '../../reduxs/home/action';


const UnsupportedWebGLPopup = () => {
  const dispatch = useDispatch();
  const handleClick = e => {
    e.preventDefault();
    dispatch(reqSetIsShowUnSupportedWebGLPopup(false));
  }

  return (
    <>
      <div className="bg-trim" onClick={e => handleClick(e)}/>
      <div className="wrap-guide-popup h-100">
        <div className="wrap-body">
          <div className="description">
            <p>
              Your version of Android device doesn&apos;t support WebGL. Click
              this link to go to our Estate Viewer
            </p>
          </div>
          <div className="link">
            <a href="https://eynesbury.com.au/real-estate/land-for-sale/">
              House For Sale
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnsupportedWebGLPopup;
