import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { reqSetIsShowCategoryDetail, reqSetIsShowSidebar } from "../../reduxs/home/action";
import { reqSetActiveType } from "../../reduxs/locations/action";
import './sidebar-collapsed.scss'
;
const SidebarCollapsed = () => {
  const dispatch = useDispatch();

  const isShowIntroFutureBtn = useSelector((state) => state.home.isShowIntroFutureBtn);

  const onShowSidebar = () => {
    dispatch(reqSetIsShowSidebar(true));
    dispatch(reqSetIsShowCategoryDetail(false));
    dispatch(reqSetActiveType(''));
  }

  return (
    <div className="wrap-sidebar-mobile-collapsed" onClick={onShowSidebar} style={{zIndex: isShowIntroFutureBtn ? 1051 : 1000 }}>
      <svg width="123" height="148" viewBox="0 0 123 148" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bd_1137_2993)">
          <circle cx="74" cy="64" r="30" fill="white" fillOpacity="0.88" shapeRendering="crispEdges" />
        </g>
        <path d="M71 70L64 74V58L71 54M71 70L78 74M71 70V54M78 74L84 70V54L78 58M78 74V58M78 58L71 54" stroke="#010131" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <defs>
          <filter id="filter0_bd_1137_2993" x="0" y="0" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1137_2993" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="22" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.188235 0 0 0 0.52 0" />
            <feBlend mode="normal" in2="effect1_backgroundBlur_1137_2993" result="effect2_dropShadow_1137_2993" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1137_2993" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export default SidebarCollapsed;
