import React from "react";
import { useSelector } from "react-redux";
import { PAGES } from "../../constants/options";
import './mobile.scss';

const BottomNavigation = (props) => {
  const { handleClickGallery, handleClickInteractiveTour, handleClickVirtualGallery, handleClickGuidedTour } = props;
  const page = useSelector((state) => state.home.page);

  const getActiveClass = (name) => {
    return page == name ? 'active' : '';
  }

  return (
    <div className="wrap-bottom-navigation-mobile">
      <div onClick={handleClickInteractiveTour} className={`item d-flex flex-column justify-content-center align-items-center ${getActiveClass(PAGES.INTERACTIVE_TOUR)}`}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 13.8744C19.5318 14.5688 22 16.1547 22 18C22 20.4853 17.5228 22.5 12 22.5C6.47715 22.5 2 20.4853 2 18C2 16.1547 4.46819 14.5688 8 13.8744M12 17.5V9.5M12 9.5C13.6569 9.5 15 8.15685 15 6.5C15 4.84315 13.6569 3.5 12 3.5C10.3431 3.5 9 4.84315 9 6.5C9 8.15685 10.3431 9.5 12 9.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>MAP</span>
      </div>
      <div onClick={handleClickGuidedTour} className={`item d-flex flex-column justify-content-center align-items-center ${getActiveClass(PAGES.GUIDED_TOUR_PAGE)}`}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.5 9.46533C9.5 8.98805 9.5 8.74941 9.59974 8.61618C9.68666 8.50007 9.81971 8.42744 9.96438 8.4171C10.1304 8.40525 10.3311 8.53429 10.7326 8.79239L15.4532 11.8271C15.8016 12.051 15.9758 12.163 16.0359 12.3054C16.0885 12.4298 16.0885 12.5702 16.0359 12.6946C15.9758 12.837 15.8016 12.949 15.4532 13.1729L10.7326 16.2076C10.3311 16.4657 10.1304 16.5948 9.96438 16.5829C9.81971 16.5726 9.68666 16.4999 9.59974 16.3838C9.5 16.2506 9.5 16.012 9.5 15.5347V9.46533Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>VIDEO</span>
      </div>
      <div onClick={handleClickGallery} className={`item d-flex flex-column justify-content-center align-items-center ${getActiveClass(PAGES.GALLERY_PAGE)}`}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.27209 21.2279L10.8686 14.6314C11.2646 14.2354 11.4627 14.0373 11.691 13.9632C11.8918 13.8979 12.1082 13.8979 12.309 13.9632C12.5373 14.0373 12.7354 14.2354 13.1314 14.6314L19.6839 21.1839M14 15.5L16.8686 12.6314C17.2646 12.2354 17.4627 12.0373 17.691 11.9632C17.8918 11.8979 18.1082 11.8979 18.309 11.9632C18.5373 12.0373 18.7354 12.2354 19.1314 12.6314L22 15.5M10 9.5C10 10.6046 9.10457 11.5 8 11.5C6.89543 11.5 6 10.6046 6 9.5C6 8.39543 6.89543 7.5 8 7.5C9.10457 7.5 10 8.39543 10 9.5ZM6.8 21.5H17.2C18.8802 21.5 19.7202 21.5 20.362 21.173C20.9265 20.8854 21.3854 20.4265 21.673 19.862C22 19.2202 22 18.3802 22 16.7V8.3C22 6.61984 22 5.77976 21.673 5.13803C21.3854 4.57354 20.9265 4.1146 20.362 3.82698C19.7202 3.5 18.8802 3.5 17.2 3.5H6.8C5.11984 3.5 4.27976 3.5 3.63803 3.82698C3.07354 4.1146 2.6146 4.57354 2.32698 5.13803C2 5.77976 2 6.61984 2 8.3V16.7C2 18.3802 2 19.2202 2.32698 19.862C2.6146 20.4265 3.07354 20.8854 3.63803 21.173C4.27976 21.5 5.11984 21.5 6.8 21.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>GALLERY</span>
      </div>
      <div onClick={handleClickVirtualGallery} className={`item d-flex flex-column justify-content-center align-items-center ${getActiveClass(PAGES.VIRTUAL_GALLERY)}`}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 5.50001L16 19.5M10 4.50001L10 20.5M3 12.5H21M3 6.48918L3 18.5108C3 19.8749 3 20.5569 3.28134 21.0296C3.52803 21.4441 3.9162 21.7555 4.37434 21.9064C4.89685 22.0785 5.56262 21.9305 6.89418 21.6346L18.4942 19.0569C19.3883 18.8582 19.8354 18.7588 20.1691 18.5184C20.4634 18.3063 20.6945 18.0182 20.8377 17.6849C21 17.307 21 16.849 21 15.9331V9.06696C21 8.15102 21 7.69306 20.8377 7.31514C20.6945 6.9818 20.4634 6.69367 20.1691 6.48162C19.8354 6.2412 19.3883 6.14185 18.4942 5.94316L6.89418 3.36538C5.56262 3.06948 4.89685 2.92153 4.37434 3.09362C3.9162 3.24451 3.52803 3.5559 3.28134 3.97039C3 4.44312 3 5.12514 3 6.48918Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>360 TOUR</span>
      </div>
    </div>
  );
}

export default BottomNavigation;
