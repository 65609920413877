import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useThree, extend, useFrame, useLoader } from 'react-three-fiber';
import * as THREE from 'three';
import { CustomControls } from './CustomControls';
import { OrbitCustomControls2 } from './OrbitCustomControls';
import { Vector3, Euler } from 'three';
import "./style.scss";
import Annotations from './Annotations';
import { Provider } from 'react-redux';
import store from './../../reduxs/store';
import { useDispatch, useSelector } from 'react-redux';
import { reqSetIsShowUnSupportedWebGLPopup, reqIsWebGLSupported } from '../../reduxs/home/action';

extend({ CustomControls });
extend({ OrbitCustomControls2 });

const CanvasBox = React.memo(React.forwardRef((props, refScene) => {
  const {
    _3dSetting,
    controls,
    fbxs,
    locations,
    isIntroduction,
    objects,
    isMobile,
  } = props;

  const dispatch = useDispatch();

  const light = useRef();
  let timeVector3 = new Vector3(0, 0, 0);

  let isCameraAnimation = false;
  let position = new THREE.Vector3();

  const [isCameraAnimated, setCameraAnimated] = useState(false);

  let meshInstanceMap = {};

  populateMeshInstanceMapKeys(fbxs);
  associateModelsToMap(objects);


  function getFbxFileName(fbx) {
    let name = fbx.name;
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  };

  function getModelFileName(model){
    let name = model['3d_filename'];
    // remove file extension
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  }

  function populateMeshInstanceMapKeys(fbxs) {
    fbxs.forEach(fbx => {
      let entry = { model: fbx, instances: [] };
      let key = getFbxFileName(fbx);
      meshInstanceMap[key] = entry;
    });
  }

   function associateModelsToMap(objects) {
    objects.forEach(obj => {
      // Make assumption that we can remove .fbx from file_name
      let name = getModelFileName(obj);
      if (!meshInstanceMap[name]) {
        console.warn('No FBX File supplied for', obj)
        return
      }
      meshInstanceMap[name].instances.push(obj);
    });
  }

  function threePosition(data) {
    threePosition2(data, position);
    return position;
  }

  function threePosition2(data, vector) {
    vector.x = data.x;
    vector.y = data.z;
    vector.z = -data.y;
  }

  const RenderInstance = (instance, model) => {
    const children = model.scene?.children ?? model.children;

    children.map((mesh_, index) => {
      const userData = {
        layer: instance.layer
      }

      Object.assign(mesh_, { userData: userData, name: instance.id });
    });

    return children.map((mesh_, index) => {
      let meshInstance =
        <mesh
          key={index}
          {...mesh_}
          layers={instance.layer != null ? instance.layer : null}
          userData={mesh_.userData}
          name={instance.id}
        />
      return meshInstance;
    });
  }

  function FbxModel() {
    if (!isIntroduction) {
      return <group />;
    }

    return (
      <group ref={refScene}>
         {Object.keys(meshInstanceMap).map((entry) => {
          const targetMap = meshInstanceMap[entry];
          if (!targetMap) return;
          const model = targetMap.model;
          const instances = targetMap.instances;
          return instances.map(instance => { return RenderInstance(instance, model); });
      })}
      </group>
    );
  }

  const AnimationCamera = React.memo((props) => {
    const {animation3dSetting, controls} = props;
    const {
      camera,
    } = useThree();


    const position = new THREE.Vector3();
    const lookAtPosition = animation3dSetting != null && animation3dSetting.cam_focus_position != null ?
     threePosition(animation3dSetting.cam_focus_position) :
     new THREE.Vector3(-102.89578369966134, -1.1178292546754195e-14, 131.5388245709879);
    const targetPosition = animation3dSetting != null && animation3dSetting.cam_position != null ?
    threePosition(animation3dSetting.cam_position) :
    new THREE.Vector3(-92.46747002504912, 260.2837561175679, 391.6135906913746);
    const delta = new Vector3(-200 - targetPosition.x, 270 - targetPosition.y, -630 - targetPosition.z);

    setCameraAnimated(true);

    camera.position.copy(new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z));
    camera.updateProjectionMatrix();

    timeVector3.x = 0;
    timeVector3.y = 0;
    timeVector3.z = 0;

    return <group />;
  });
  AnimationCamera.displayName = 'AnimationCamera';

  const CameraControls = React.memo(() => {
    const {
      camera,
      gl,
      raycaster
    } = useThree();
    const domElement = gl.domElement;

    gl.info.autoReset = false;
    useThree().gl.setSize(
      Math.min(window.innerWidth, 1280),
      Math.min(window.innerHeight, 720),
      false
    );

    if (isIntroduction) {
      useFrame(({ gl, scene, camera }) => {
        gl.render(scene, camera);
      }, 1);
    }

    useFrame(() => {
      if (!isCameraAnimation && isCameraAnimated) {
        if (controls != null && controls.current != null) {
          controls.current.update();
        }
        return;
      }
    });

    return (
      <orbitCustomControls2
        ref={controls}
        args={[camera, domElement, [_3dSetting.start_curve_position.x, _3dSetting.start_curve_position.y, _3dSetting.start_curve_position.z], [_3dSetting.end_curve_position.x, _3dSetting.end_curve_position.y, _3dSetting.end_curve_position.z]]}
        raycaster={raycaster}
        disabledUpdate={false}
        neverUpdate={false}
        autoRotate={false}
        enableDamping={true}
        maxDistance={3640}
        minDistance={2}
        zoomSpeed={2}
        rotateSpeed={0.8}
        minZoom={_3dSetting.minZoom ?? 0.2}
        maxZoom={_3dSetting.maxZoom ?? 8}
        movingCurveSpeed={_3dSetting.movingCurveSpeed ?? 0.5}
       />
    );
  });
  CameraControls.displayName = 'CameraControls';

  const isWebGLSupported = useSelector(state => state.home.isWebGLSupported);
  const handleContext = (gl) => {
    // --> uncomment this line to test the context lost event
    // gl.forceContextLoss()

    const canvas = gl.domElement;
    canvas.addEventListener(
      'webglcontextlost',
      (event) => {
        event.preventDefault();
        dispatch(reqIsWebGLSupported(false));
        dispatch(reqSetIsShowUnSupportedWebGLPopup(true));
      },
      false
    );
  };

  return (
    <>
      {
        isWebGLSupported && <Canvas
        gl={{
          outputEncoding: THREE.sRGBEncoding ,
          logarithmicDepthBuffer : false,
          stencil: false,
          precision: 'lowp',
          outputEncoding : THREE.sRGBEncoding,
        }}
        pixelRatio={1}
        camera={{
          position: [
            1020 + _3dSetting.cam_position.x,
            540 + _3dSetting.cam_position.z,
            630 - _3dSetting.cam_position.y,
          ],
          fov: _3dSetting.FOV,
          near: 1,
          far: 5000,
        }}
        onCreated={({ gl }) => handleContext(gl)}
      >
        {isIntroduction && !isCameraAnimated && <AnimationCamera animation3dSetting={_3dSetting} controls={controls} />}
        <ambientLight intensity={0.2} color={0x2e2e2a} />
        {true && (
          <hemisphereLight
            intensity={0.4}
            skyColor={0xb1e1ff}
            groundColor={0x2e2e2a}
            position={[0, -10, 0]}
          />
        )}
        <directionalLight
          ref={light}
          intensity={1.6}
          color={0xffffff}
          position={[-1500, 600, 250]}
        />
        {true && <directionalLight intensity={0.7} color={0xffffff} position={[1500, 600, -250]} />}
        <React.Suspense fallback={null}>
          <FbxModel />
          <Provider store={store}>
            {<Annotations controls={controls} locations={locations} isMobile={isMobile} />}
          </Provider>
        </React.Suspense>
        { <CameraControls />}
      </Canvas>
      }
    </>
  );
}));

CanvasBox.displayName = 'CanvasBox';

export default CanvasBox;
