import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PANOLENS from 'panolens';
import { ImageLoader } from 'three';
import { reqSetIsShowVirtualTour } from '../../reduxs/home/action';
import { reqSetExploreModal } from '../../reduxs/explore-modal/action';
import './virtual-tour.scss';

const VirtualTour = () => {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.exploreModal.modal);

  const loader = new ImageLoader();
  const [panos, setPanos] = useState([]);
  const [viewer, setViewer] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClickCancel = () => {
    viewer?.dispose();
    dispatch(reqSetIsShowVirtualTour(false));
    dispatch(reqSetExploreModal(undefined));
  };

  const onInitPanorama = () => {
    let selector = document.querySelector("#wrap-panorama-mobile");
    if (selector != undefined) {
      selector.replaceChildren();
    }
    let testPanos = [];
    if (modal == undefined || modal.virtual_links == undefined) {
      return;
    }
    const length = modal.virtual_links.length;
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      testPanos.push(undefined);
    }
    setPanos(testPanos);

    const firstChild = modal.virtual_links[0];
    const image1stURL = firstChild.virtual_link;
    loader.load(image1stURL, function (image) {
      let pano = new PANOLENS.ImagePanorama(image);

      panos[0] = pano;
      viewer.add(pano);
      viewer.enableControl(PANOLENS.CONTROLS.ORBIT);
      viewer.setPanorama(pano);
      setPanos(panos);

      for (let j = 0; j < firstChild.infospots.length; j++) {
        let spot = firstChild.infospots[j];
        let infospot = new PANOLENS.Infospot(
          spot.scale,
          spot.image
        );

        infospot.position.set(spot.position[0], spot.position[1], spot.position[2]);
        infospot.addEventListener("click", () => {
          console.log(spot.targetIndex);
          setActiveIndex(spot.targetIndex);
          if (panos[spot.targetIndex] != undefined) {
            viewer.add(panos[spot.targetIndex]);
            viewer.enableControl(PANOLENS.CONTROLS.ORBIT);
            viewer.setPanorama(panos[spot.targetIndex]);
          }
        });

        pano.add(infospot);
      }

      for (let index = 1; index < modal.virtual_links.length; index++) {
        const child = modal.virtual_links[index];
        const imageURL = child.virtual_link;

        loader.load(imageURL, function (image) {
          const panoOther = new PANOLENS.ImagePanorama(image);

          panos[index] = panoOther;
          //viewer.add(panoOther);
          setPanos(panos);

          for (let j = 0; j < child.infospots.length; j++) {
            let spot = child.infospots[j];
            const infospot = new PANOLENS.Infospot(
              spot.scale,
              spot.image
            );

            infospot.position.set(spot.position[0], spot.position[1], spot.position[2]);
            infospot.addEventListener("click", function () {
              if (panos[spot.targetIndex] != undefined) {
                viewer.add(panos[spot.targetIndex]);
                viewer.enableControl(PANOLENS.CONTROLS.ORBIT);
                viewer.setPanorama(panos[spot.targetIndex]);
                setActiveIndex(spot?.targetIndex);
              }
            });
            infospot.show();

            panoOther.add(infospot);
          }

        }, undefined, function (error) {
          console.error('An error happened.', error);
        });
      };
    }, undefined, function (error) {
      console.error('An error happened.', error);
    });

    let viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama-mobile"),
      controlButtons: [],
      autoHideInfospot: false
    });
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    setViewer(viewer);
  }

  useEffect(() => {
    if (modal) {
      onInitPanorama();

      return () => {
        viewer?.dispose();
      }
    }
  }, [modal]);

  const onClickDotItem = (index) => {
    if (panos[index] == undefined) return;

    const pano = panos[index];
    viewer.add(pano);
    viewer.enableControl(PANOLENS.CONTROLS.ORBIT);
    setActiveIndex(index);
    viewer.setPanorama(pano);
  }

  const onNextPano = () => {
    if(activeIndex >= modal.virtual_links.length - 1) return;

    if (panos[activeIndex] == undefined) return;

    const pano = panos[activeIndex + 1];
    viewer.add(pano);
    viewer.enableControl(PANOLENS.CONTROLS.ORBIT);
    setActiveIndex(activeIndex + 1);
    viewer.setPanorama(pano);
  }

  const onPrevPano = () => {
    if (activeIndex < 1) return;

    if (panos[activeIndex] == undefined) return;

    const pano = panos[activeIndex - 1];
    viewer.add(pano);
    viewer.enableControl(PANOLENS.CONTROLS.ORBIT);
    setActiveIndex(activeIndex - 1);
    viewer.setPanorama(pano);
  }

  const Dotted = () => {
    return (
      <div className='dots-container'>
        <div className='wrap-dots'>
          {
            (modal?.virtual_links || []).map((item, index) => {
              return (
                <div key={index} onClick={() => onClickDotItem(index)} className={`dot-item ${activeIndex === index ? 'active' : ''}`} />
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='wrap-virtual-tour'>
        <div className='bg-image'>
          <div className='header'>
            <div className='logo'>
              <img className='img-fluid' src="/images/logo.svg" alt="logo" />
            </div>
            <button onClick={handleClickCancel} className="close-btn">
              <svg viewBox="0 0 24 24" role="img" tabIndex="-1" xmlns="http://www.w3.org/2000/svg"><path d="M20 20L4 4m16 0L4 20" /></svg>
            </button>
          </div>
          <div className='nav-btn'>
            <button onClick={onPrevPano} className='carousel__button prev'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabIndex="-1"><path d="M15 3l-9 9 9 9" /></svg>
            </button>
            <Dotted/>
            <button onClick={onNextPano} className='carousel__button next'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabIndex="-1"><path d="M9 3l9 9-9 9" /></svg>
            </button>
          </div>
          <div id="wrap-panorama-mobile" className="img-panorama" />
        </div>
      </div>
    </>
  )
}

export default VirtualTour;
