import React from "react";
import { useDispatch } from "react-redux";
import { PAGES } from "../../constants/options";
import { reqSetPage } from "../../reduxs/home/action";
import './mobile.scss';

const TopNav = () => {
  const dispatch = useDispatch();

  const handleClickLogo = () => {
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
  }

  return (
    <div className="wrap-top-nav-mobile" onClick={handleClickLogo}>
      <div className='logo h-100'>
        <img className='h-100' src="/images/logo.svg" alt="" />
      </div>
    </div>
  );
}

export default TopNav;
