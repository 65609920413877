import React, { useState, useEffect } from "react";
import './mobile.scss';
import Fancybox from "./fancy-box";
import galleryApi from '../../apis/api/gallery';

const HomeGallery = () => {
  const [gallery, setGallery] = useState();

  useEffect(() => {
    getGalleryDetail();
  }, []);

  const getGalleryDetail = async () => {
    const res = await galleryApi.getGalleryDetail('60cb329ee76f1f0016defcff');
    setGallery(res.data);
  }

  const ListImages = () => {
    return (gallery?.media || []).map((item, index) => {
      return (
        <div key={index} className="item">
          <div className={`h-100`} style={{backgroundImage: `url(${item.thumbnail})`}} data-fancybox="gallery" data-src={item.path} />
        </div>
      )
    })
  }

  return (
    <div className="wrap-gallery-mobile">
      <div className="header">
        <div className="logo">
          <img src="/images/logo.svg" alt="" />
        </div>
        <span>IMAGE GALLERY</span>
      </div>
      <div className="body">
      <Fancybox options={{ infinite: true, mainClass: 'wrap-fancy-box-mobile', showNavArrows: false }}>
        <div className="wrap-list-images">
          <ListImages/>
        </div>
      </Fancybox>
      </div>
    </div>
  );
}

export default HomeGallery;
