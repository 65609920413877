import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reqSetExploreModal } from "../../reduxs/explore-modal/action";
import { reqGetListVirtualModals, reqSetIsShowHotspotModal, reqSetIsShowVirtualTour } from "../../reduxs/home/action";
import './mobile.scss';

const VirtualGallery = () => {
  const dispatch = useDispatch();
  const virtualModals = useSelector((state) => state.home.virtualModals);

  useEffect(() => {
    dispatch(reqGetListVirtualModals());
  }, []);

  const onShowVirtualModal = (modalId) => {
    dispatch(reqSetIsShowVirtualTour(true));
    dispatch(reqSetExploreModal(modalId));
  }

  const ListVirtualImages = () => {
    return virtualModals.map((modal, index) => {
      return (
        <div key={index} className="virtual-item">
          <h3 className="text-center">{modal.name}</h3>
          <div className="wrap-list-images">
            {
              (modal.virtual_links || []).map((item, idx) => {
                return (
                  <div key={idx} className="item">
                    <img onClick={() => onShowVirtualModal(modal.id)} className="cursor-pointer" src={item.thumbnail} alt="" />
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    })
  }

  return (
    <div className="wrap-virtual-gallery-mobile">
      <div className="header">
        <div className="logo">
          <img src="/images/logo.svg" alt="" />
        </div>
        <span>360 TOUR GALLERY</span>
      </div>
      <div className="body">
        <ListVirtualImages />
      </div>
    </div>
  )
}

export default VirtualGallery;
