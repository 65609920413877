import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { } from "react-redux";
import { reqGetCategoriesList, reqSetActiveLocation } from "../../reduxs/category/action";
import { reqSetActiveCategory, reqSetIsCollapsedCategoryDetail, reqSetIsShowCategoryDetail, reqSetIsShowSidebar } from "../../reduxs/home/action";
import { reqSetActivePoi, reqSetActiveType } from "../../reduxs/locations/action";
import './mobile.scss';

const LegendSidebar = () => {
  const dispatch = useDispatch();

  const activeType = useSelector((state) => state.poi.activeType);
  const categories = useSelector((state) => state.category.categories);
  const isShowFuture = useSelector((state) => state.home.isShowFuture);

  const onClose = () => {
    dispatch(reqSetIsShowSidebar(false));
  }

  const onClickCategory = async (item) => {
    dispatch(reqSetActiveCategory(item));
    dispatch(reqSetIsShowSidebar(false));
    dispatch(reqSetIsShowCategoryDetail(true));
    dispatch(reqSetIsCollapsedCategoryDetail(true));
    dispatch(reqSetActivePoi(""));
    dispatch(reqSetActiveType(item.id));
  }

  const renderListCategories = () => {
    return (categories || []).map(item => {
      if (!isShowFuture && item.name == 'FUTURE DEVELOPMENTS') return;

      return (
        <div className="category" key={item.id} onClick={() => onClickCategory(item)}>
          <div>
            <span className="color-key" style={{backgroundColor: `${item.color}`}} />
            <span>{item.name}</span>
          </div>
          <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.19995 11.2L5.19995 7.20001L1.19995 3.20001L1.99995 1.60001L7.59995 7.20001L1.99995 12.8L1.19995 11.2Z" fill="#B8B5B8" stroke="#B8B5B8" />
          </svg>
        </div>
      )
    })
  }

  const renderColourKey = () => {
    return (
      <div className="wrap-colour-key">
        <div className="title">Colour key</div>
        <ul className="list-group">
          <li className="list-group-item">
            <div className="color walking-tracks" />
            Walking Tracks
          </li>
          {
            isShowFuture && (
              <li className="list-group-item">
                <div className="color mountain-bike-trails" />
                Mountain Bike Trails
              </li>
            )
          }
        </ul>
      </div>
    )
  }

  return (
    <div className="wrap-legend-sidebar-mobile">
      <div className="tray" onClick={onClose} />
      <div className="content">
        <div className="body">
          <div className="header">
            <span className="ms-3">AMENITIES</span>
            <button onClick={onClose}>
              <svg viewBox="0 0 24 24" role="img" tabIndex="-1" xmlns="http://www.w3.org/2000/svg"><path d="M20 20L4 4m16 0L4 20" /></svg>
            </button>
          </div>
          <div className="wrap-list-categories">
            {renderListCategories()}
          </div>
        </div>
        <div className="footer">
          {renderColourKey()}
        </div>
      </div>
    </div>
  );
}

export default LegendSidebar;
