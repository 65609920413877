export default {
  // guest
  ROOT: '/',
  LOGIN_SCREEN: '/sign-in',
  LOCATION: '/location',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  REQUEST_FORM: '/request-form',
  UNIT_LIST_PAGE: '/units',
  ASSETS_PAGE: '/performance-test',
  TEST_PAGE: '/test',
  FLOOR_COMPARISON: '/floor-comparison',
  DASHBOARD_PAGE: '/dashboard',
  NOTFOUND_PAGE: '/404',
  HODLDING_PAGE: '/holding',
  EMAIL_LOGIN_PAGE: '/invitation-setup',
  CMS_PAGE: '/cms',
  CUSTOMER_SESSION_PAGE: '/post-engagement/:customerId',
  MOBILE_PAGE: '/mobile',
};
