import React, { useEffect, useRef, useState } from 'react';
import CanvasBox from '../components/3d-scene/CanvasBox';
import _3dSettings from '../apis/api/_3dSettings';

import { connect } from 'react-redux';
import { Vector3 } from 'three';
import ReactUIMobile from './reactui/mobile';
import { useHistory } from 'react-router-dom'

const RefCanvasBox = React.forwardRef((props, ref) => <CanvasBox ref={ref} {...props} />);
RefCanvasBox.displayName = 'RefCanvasBox';

const Index = (props) => {
  const { roles, authMiddleware } = props;
  const [isIntroduction, setIsIntroduction] = useState(false);
  const controls = useRef();
  let refScene = useRef();
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  const renderCanvasBox = () => {
    /*if (!(!!props.assets.length && !!props.hotspots.length && Object.keys(props.settings).length && props.fbxs.length && props.pagesSettings != undefined))
      return <div/>;*/

    if (!(props.locations != undefined && !!props.locations.length && !!props.hotspots.length && props.settings != undefined && Object.keys(props.settings).length && props.pagesSettings != undefined))
      return <div/>;


    if (props.isLoading) {
      return <div/>;
    }

    let ids = [];

    return (
        <CanvasBox
          ref={refScene}
          controls={controls}
          objects={props.assets}
          hotspots={props.hotspots}
          _3dSetting={props.settings}
          fbxs={props.fbxs}
          pagesSettings={props.pagesSettings}
          isIntroduction={true}
          targetPosition={controls.current?.getCurrentTarget() ?? new Vector3()}
          locations={props.locations}
          isMobile={true}
        />
    )
  }

  return (
    <>
      {renderCanvasBox()}
      <ReactUIMobile
          isIntroduction={isIntroduction}
          controls={controls}
          refScene={refScene}
        />
    </>
  );
};

const mapStateToProps = (state) => {
  const { scene } = state;
  return {
    settings: scene.settings,
    assets: scene.assets,
    hotspots: scene.hotspots,
    fbxs: scene.fbxs,
    isLoading: scene.isLoading,
    pagesSettings: scene.pagesSettings,
    locations: scene.locations
  };
};
export default connect(mapStateToProps)(Index);

