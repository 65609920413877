import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reqSetIsShowIntroFutureBtn } from "../../reduxs/home/action";

const IntroFutureBtn = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(reqSetIsShowIntroFutureBtn(false));
  }

  return (
    <div className="bg-trim" onClick={onClose}>
      <div className="wrap-intro-future-btn">
        <span>Click here to see
          everything Eynesbury
          has to offer!</span>
      </div>
    </div>
  )
}

export default IntroFutureBtn;
