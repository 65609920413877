import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import { reqSetActiveLocation } from "../../reduxs/category/action";
import { reqSetExploreModal } from "../../reduxs/explore-modal/action";
import { reqSetIsShowCategoryDetail, reqSetIsShowVirtualTour } from "../../reduxs/home/action";
import { reqSetActiveType } from "../../reduxs/locations/action";
import './location-detail.scss';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrow: false,
};

const LocationDetail = () => {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.exploreModal.modal);
  const activeCategory = useSelector((state) => state.home.activeCategory);

  const onBackToCategoryDetail = () => {
    if (activeCategory == undefined || activeCategory === false) {
      onClose();
      return;
    }
    dispatch(reqSetActiveLocation(''));
    dispatch(reqSetExploreModal(null));
    dispatch(reqSetIsShowCategoryDetail(true));
  }

  const onClose = () => {
    dispatch(reqSetIsShowCategoryDetail(false));
    dispatch(reqSetActiveLocation(''));
    dispatch(reqSetActiveType(''));
  }

  const handleOpenVirtualTour = () => {
    dispatch(reqSetExploreModal(modal.virtual_modal));
    dispatch(reqSetIsShowVirtualTour(true));
    dispatch(reqSetIsShowCategoryDetail(false));
    dispatch(reqSetActiveLocation(''));
  }

  const VirtualBtn = () => {
    if (modal?.virtual_modal) {
      return (
        <div className="wrap-360-btn" onClick={handleOpenVirtualTour}>
          <img className="img-fluid" src="./icons/VT_icon.svg" alt="" />
        </div>
      );
    }
    return (<div/>);
  }

  const FutureComponent = () => {
    if (activeCategory?.name == 'FUTURE DEVELOPMENTS') return (
      <div className="w-100 future-development">
      <span className="color-key" style={{backgroundColor: `${activeCategory.color}`}}/>
      <span>Future development</span>
    </div>
    );

    return (<div/>);
  }

  return (
    <div className="wrap-location-detail-mobile">
      <div className="tray" onClick={onClose} />
      <div className="content">
        <div className="header">
          {activeCategory !== undefined && <button onClick={onBackToCategoryDetail}>
            <svg className="back-btn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>}
          <span>{modal?.title}</span>
          <button onClick={onClose}>
            <svg className="close-btn" viewBox="0 0 24 24" role="img" tabIndex="-1" xmlns="http://www.w3.org/2000/svg"><path d="M20 20L4 4m16 0L4 20" /></svg>
          </button>
        </div>
        <div className="body">
          <div className="wrap-gallery">
            <Slider {...settings}>
              {
                (modal?.image_links || []).map((item, index) => {
                  return (
                    <img key={index} className="h-100 w-100" src={item} alt="" />
                  )
                })
              }
            </Slider>
            <VirtualBtn/>
          </div>
          <div className="wrap-description">
            <FutureComponent/>
            {modal?.description}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationDetail;
