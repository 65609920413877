import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { PAGES } from "../../constants/options";
import { reqSetPage } from "../../reduxs/home/action";
import './mobile.scss';

const VideoIntro = () => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted
    }
  }, [isMuted]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState === 4) {
      videoRef.current.play();
    }
  }, [videoRef]);

  const onMuteGuidedTourVideo = () => {
    setIsMuted(!isMuted);
  }

  const onSkipVideo = () => {
    dispatch(reqSetPage(PAGES.INTERACTIVE_TOUR));
  }


  return (
    <div className={`wrap-video-intro-mobile`}>
      <div className="header">
        <div className="wrap-logo h-100">
          <img src="/images/logo.svg" alt="" />
        </div>
        <div className='wrap-btn-control-video d-flex flex-row'>
          <button className='me-2' onClick={onSkipVideo}>Skip</button>
          {
            isMuted ? (<button onClick={onMuteGuidedTourVideo} className="d-flex flex-row align-items-center">
              <span className='me-2'>Off</span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5458 1.63133C13.025 1.87508 13.3333 2.40945 13.3333 3.00008V21.0001C13.3333 21.5907 13.025 22.1251 12.5458 22.3688C12.0667 22.6126 11.5042 22.5141 11.1125 22.1204L5.49167 16.5001H2.66667C1.19583 16.5001 0 15.1548 0 13.5001V10.5001C0 8.84539 1.19583 7.50008 2.66667 7.50008H5.49167L11.1125 1.87977C11.5042 1.48602 12.0667 1.39227 12.5458 1.63133V1.63133ZM17.7083 7.8282L20 10.4063L22.2917 7.8282C22.6833 7.38758 23.3167 7.38758 23.7042 7.8282C24.0917 8.26883 24.0958 8.98133 23.7042 9.41727L21.4125 11.9954L23.7042 14.5735C24.0958 15.0141 24.0958 15.7266 23.7042 16.1626C23.3125 16.5985 22.6792 16.6032 22.2917 16.1626L20 13.5845L17.7083 16.1626C17.3167 16.6032 16.6833 16.6032 16.2958 16.1626C15.9083 15.722 15.9042 15.0095 16.2958 14.5735L18.5875 11.9954L16.2958 9.41727C15.9042 8.97664 15.9042 8.26414 16.2958 7.8282C16.6875 7.39227 17.3208 7.38758 17.7083 7.8282V7.8282Z" fill="white" />
              </svg>
            </button>) : (<button onClick={onMuteGuidedTourVideo} className="d-flex flex-row align-items-center">
              <span className='me-2'>On</span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7479 4.99993C21.1652 6.97016 22 9.38756 22 11.9999C22 14.6123 21.1652 17.0297 19.7479 18.9999M15.7453 7.99993C16.5362 9.13376 17 10.5127 17 11.9999C17 13.4872 16.5362 14.8661 15.7453 15.9999M9.63432 5.36561L6.46863 8.5313C6.29568 8.70425 6.2092 8.79073 6.10828 8.85257C6.01881 8.9074 5.92127 8.9478 5.81923 8.9723C5.70414 8.99993 5.58185 8.99993 5.33726 8.99993H3.6C3.03995 8.99993 2.75992 8.99993 2.54601 9.10892C2.35785 9.20479 2.20487 9.35777 2.10899 9.54594C2 9.75985 2 10.0399 2 10.5999V13.3999C2 13.96 2 14.24 2.10899 14.4539C2.20487 14.6421 2.35785 14.7951 2.54601 14.8909C2.75992 14.9999 3.03995 14.9999 3.6 14.9999H5.33726C5.58185 14.9999 5.70414 14.9999 5.81923 15.0276C5.92127 15.0521 6.01881 15.0925 6.10828 15.1473C6.2092 15.2091 6.29568 15.2956 6.46863 15.4686L9.63431 18.6342C10.0627 19.0626 10.2769 19.2768 10.4608 19.2913C10.6203 19.3038 10.7763 19.2392 10.8802 19.1175C11 18.9773 11 18.6744 11 18.0686V5.9313C11 5.32548 11 5.02257 10.8802 4.88231C10.7763 4.76061 10.6203 4.69602 10.4608 4.70858C10.2769 4.72305 10.0627 4.93724 9.63432 5.36561Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>)
          }
        </div>
      </div>
      <video
        ref={videoRef}
        autoPlay={true}
        preload="auto"
        className="intro-video"
        playsInline
      >
        <source src="/uploads/videos/RES2562_Eynesbury_Edit_001_Animatic.mp4" type="video/mp4" />
      </video>

    </div>
  );
}

export default VideoIntro;
